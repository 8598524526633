export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"keywords","content":"免费问卷系统，调查问卷，表单收集，开源问卷调查，开源调查问卷，开源表单，开源问卷调查系统，问卷调查系统，开源表单系统，表单系统，在线问卷，开源表单系统，大学生调查问卷表单系统,开源问卷调查系统,开源表单软件,tduck官网,数据收集系统,开源信息填报系统,信息填报,填报系统,信息收集,自定义表单,工作流表单,拖拽表单,湖南省众达数蔚信息技术有限公司官网,tduck后台管理框架,tduck源码分享,tduck-pro源码,tduck官网,tduck-pro文档,tduck-pro,tduck-pro官网,tduck文档"},{"name":"description","content":"填鸭表单为企业提供专业的调查问卷系统，表单系统，是一款好用的开源问卷系统，拥有所见可视化直观的编辑和结果统计界面，多样化在线调查问卷收集渠道。适用在线调查问卷，大学生问卷，员工满意度调查,市场调查,人才测评,学术论文,表单收款,活动投票等调查问卷服务。"},{"name":"apple-mobile-web-app-status-bar-style","content":"black-translucent"}],"link":[],"style":[],"script":[],"noscript":[],"title":"开源问卷系统-TduckSurvey填鸭表单","viewport":"width=device-width,initial-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'