import { UseFetchOptions } from '#app'
import { createDiscreteApi } from 'naive-ui'
import { FetchResponse } from 'ofetch'

const { message } = createDiscreteApi(
  ['message']
)

export interface ResOptions<T> {
  data: T
  code: number
  message: string
  success: boolean
}

type UrlType = string | Request | Ref<string | Request> | (() => string | Request)

export type HttpOption<T> = UseFetchOptions<ResOptions<T>>

function handleError<T> (response: FetchResponse<ResOptions<T>> & FetchResponse<ResponseType>) {
  const err = (text: string) => {
    message.error(text)
  }
  if (!response._data) {
    err('请求超时，服务器无响应！')
    return
  }
  // const userStore = useUserStore()
  const handleMap: { [key: number]: () => void } = {
    404: () => err('服务器资源不存在'),
    500: () => err('服务器内部错误'),
    403: () => err('没有权限访问该资源'),
    401: () => {
      err('登录状态已过期，需要重新登录')
      navigateTo('/')
    }
  }
  handleMap[response.status] ? handleMap[response.status]() : err('未知错误！')
}

const getOptions = (option) => {
  const runtimeConfig = useRuntimeConfig()
  const token = useCookie('token')
  return {
    baseURL: runtimeConfig.public.apiBase,
    transform: (res) => {
      return (res as any).data
    },
    // 请求拦截器
    onRequest ({ options }) {
      // 添加请求头,没登录不携带token
      options.headers = new Headers(options.headers)
      options.headers.set('Authorization', `Bearer ${token.value}`)
      console.log('api options')
      console.log(options)
    },
    onRequestError ({ request, options, error }) {
      // 处理请求错误
      console.log('onRequestError')
      console.log(request)
      console.log(options)
      console.log(error)
    },
    // 响应拦截
    onResponse ({ response }) {
      console.log('api response')
      console.log(response)
      if (response.headers.get('content-disposition') && response.status === 200) { return response }
      // 在这里判断错误
      if (response._data.code !== 200) {
        if (process.client) {
          if (response._data.code === 401) {
            navigateTo('/login')
          } else if (response._data.code === 500) {
            message.error(response._data.msg)
            return Promise.reject(response._data)
          }
        }
        return Promise.reject(response._data)
      }
    },
    // 错误处理
    onResponseError ({ response }) {
      console.log('err')
      console.log(response)
      if (process.client) {
        handleError<T>(response)
      }
      return Promise.reject(response?._data ?? null)
    },
    // 合并参数
    ...option
  }
}

function fetch<T> (url: UrlType, option: any) {
  if (option.$) {
    return $fetch(url, getOptions(option))
  } else {
    return useFetch<T>(url, getOptions(option))
  }
}

// 自动导出
export const useHttp = {
  get: <T>(url: UrlType, params?: any, option?: HttpOption<T>) => {
    return fetch<T>(url, { method: 'get', params, ...option })
  },

  post: <T>(url: UrlType, body?: any, option?: HttpOption<T>) => {
    return fetch<T>(url, { method: 'post', body, ...option })
  },

  put: <T>(url: UrlType, body?: any, option?: HttpOption<T>) => {
    return fetch<T>(url, { method: 'put', body, ...option })
  },

  delete: <T>(url: UrlType, body?: any, option?: HttpOption<T>) => {
    return fetch<T>(url, { method: 'delete', body, ...option })
  }
}
