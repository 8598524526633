import revive_payload_client_angoz4Sb8c from "/root/.jenkins/workspace/tduck-cms-front/node_modules/.pnpm/nuxt@3.7.3_@opentelemetry+api@1.8.0_eslint@8.57.0_sass@1.77.2_typescript@5.4.5_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_pJnZCysrEk from "/root/.jenkins/workspace/tduck-cms-front/node_modules/.pnpm/nuxt@3.7.3_@opentelemetry+api@1.8.0_eslint@8.57.0_sass@1.77.2_typescript@5.4.5_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_S3vMmPQYAs from "/root/.jenkins/workspace/tduck-cms-front/node_modules/.pnpm/nuxt@3.7.3_@opentelemetry+api@1.8.0_eslint@8.57.0_sass@1.77.2_typescript@5.4.5_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import naive_ui_cjnU5TlW5h from "/root/.jenkins/workspace/tduck-cms-front/plugins/naive-ui.ts";
import plugin_vue3_H9Uopl6vgk from "/root/.jenkins/workspace/tduck-cms-front/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.4.5_vue@3.4.27/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/root/.jenkins/workspace/tduck-cms-front/.nuxt/components.plugin.mjs";
import prefetch_client_gzb1ZUBAYW from "/root/.jenkins/workspace/tduck-cms-front/node_modules/.pnpm/nuxt@3.7.3_@opentelemetry+api@1.8.0_eslint@8.57.0_sass@1.77.2_typescript@5.4.5_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_Kh106YnRPV from "/root/.jenkins/workspace/tduck-cms-front/node_modules/.pnpm/nuxt@3.7.3_@opentelemetry+api@1.8.0_eslint@8.57.0_sass@1.77.2_typescript@5.4.5_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import aos_client_rDSYU13oOX from "/root/.jenkins/workspace/tduck-cms-front/plugins/aos.client.ts";
export default [
  revive_payload_client_angoz4Sb8c,
  unhead_pJnZCysrEk,
  router_S3vMmPQYAs,
  naive_ui_cjnU5TlW5h,
  plugin_vue3_H9Uopl6vgk,
  components_plugin_KR1HBZs4kY,
  prefetch_client_gzb1ZUBAYW,
  chunk_reload_client_Kh106YnRPV,
  aos_client_rDSYU13oOX
]