import { useGetUserInfoRequest } from '~/apis/user'

export const useUser = () => useState('userInfo', () => null)

/**
 * 获取用户信息
 */
export async function getUserInfo () {
  const userInfo = useUser()
  const token = useCookie('token')
  if (token.value) {
    if (!userInfo.value) {
      const userRes = await useGetUserInfoRequest()
      userInfo.value = userRes.data.value?.user as any
    }
  }
}
