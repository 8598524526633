// 动态设置布局
import { getUserInfo } from '../composables/useAuth'

export default defineNuxtRouteMiddleware(async (to, from) => {
  // 只在客户端校验
  if (!process.server) {
    if (to.path.startsWith('/admin')) {
      // 是否登录了
      if (!useCookie('token').value) {
        return navigateTo({
          path: '/login',
          query: {
            redirect: to.path
          }
        })
      }
      // 检查是否获取了
      await getUserInfo()
      setPageLayout('admin')
    }
  }
})
