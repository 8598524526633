<script setup lang="ts">
import {
  NMessageProvider,
  NDialogProvider
} from 'naive-ui'
import { appName } from '~/constants'

useHead({
  title: appName,
  script: [
    {
      src: 'https://hm.baidu.com/hm.js?b4d8a977069083cbc5ee4c3aa72f36ea'
    }
  ]
})
</script>

<template>
  <NMessageProvider>
    <NDialogProvider>
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </NDialogProvider>
  </NMessageProvider>
</template>

<style>
@import "/assets/styles/index.scss";
html, body, #__nuxt {
  height: 100%;
  margin: 0;
  padding: 0;
}

html.dark {
  background: #222;
  color: white;
}
</style>
