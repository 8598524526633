/**
 * 登录
 * @param username
 * @param password
 */
export const useLoginRequest = (username: string, password: string) => {
  return useHttp.post<string>('/login', {
    username,
    password
  })
}

export interface UserProfile{
  user: {
    avtar: string
  }
}

/**
 * 获取用户信息
 */
export const useGetUserInfoRequest = () => {
  return useHttp.get<UserProfile>('/system/user/profile')
}
